import React, { Fragment, ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import CycleScoreChart from '../charts/cycle-score/cycle-score';
import { ICycleScoreChartPoint } from '../charts/cycle-score/cycle-score-interface';
import Grade from '../grade/grade';
import { GradeSize } from '../grade/grade-enum';
import PercentBox from '../percent-box/percent-box';
import { ICriteriaGradeProperties } from './criteria-grade-interface';
import './criteria-grade.scss';

export default class CriteriaGrade extends BaseComponent<ICriteriaGradeProperties> {
  protected declareTranslateCollection(): string | undefined {
    return 'criteriaGrade';
  }

  public render(): ReactNode {
    const {
      descriptions,
      averageScore,
      averagePercent,
      cycleScores,
      overallGrade,
      overallPercentage,
      overallScore,
      title,
      isDecimal,
      simpleMode = false,
    } = this.props;
    const rowClassName: string = [
      'row c-criteria-grade__container',
      simpleMode ? 'is-simple' : '',
    ].join(' ');
    const chartScores: ICycleScoreChartPoint[] = cycleScores.map(
      (item): ICycleScoreChartPoint => {
        return {
          cycle: item.cycle,
          score: item.score,
          weight: item.weight,
        };
      }
    );

    return (
      <div className="c-criteria-grade">
        <h3 className="c-score__title">{title}</h3>
        <div className={rowClassName}>
          <div className="col-md-4 c-criteria-grade__col">
            <div className="c-criteria-grade__wrap">
              <Grade grade={overallGrade} size={GradeSize.LARGE} />
              <div className="c-criteria__info">
                <span className="c-criteria-grade__overall">
                  {overallScore}
                </span>
                <span className="c-criteria-grade__average">
                  {`${this.translate('avg')}:${averageScore}`}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-7 offset-md-1 c-criteria-grade__col">
            <div data-name="chart" className="c-criteria-grade__chart">
              <CycleScoreChart
                overallGrade={overallGrade}
                chartPoints={chartScores}
              />
            </div>
          </div>
        </div>
        {!simpleMode && (
          <div className="row c-criteria-grade__container c-criteria--raw">
            <div className="col-md-4 c-criteria-grade__col">
              <div className="c-criteria-grade__wrap">
                <PercentBox
                  boxValue={overallPercentage}
                  isDecimal={isDecimal}
                />
                <div className="c-criteria__info">
                  <span className="c-criteria-grade__overall">
                    {this.translate('rawScore')}
                  </span>
                  <span className="c-criteria-grade__average">
                    {`${this.translate('avg')}:${averagePercent}${
                      isDecimal ? ' ' : ' %'
                    }`}
                  </span>
                </div>
              </div>
            </div>
            {descriptions && descriptions.length > 0 ? (
              <div className="col-md-7 offset-md-1 c-criteria-grade__col">
                {descriptions.map((desc, index) => {
                  return (
                    <Fragment key={index}>
                      <p className="c-criteria-grade__desc">{desc}</p>
                      <br />
                    </Fragment>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    );
  }
}
