import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import { IPercentBoxProperties } from './percent-box-interface';
import './percent-box.scss';

export default class PercentBox extends BaseComponent<IPercentBoxProperties> {
  public render(): ReactNode {
    const { boxValue, isDecimal = false } = this.props;

    return (
      <div className="c-percent-box">
        <div className="c-percent-box__percent">
          {`${boxValue}${isDecimal ? " " : "%"}`}
        </div>
      </div>
    );
  }
}
